import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";
import Icon from "../../components/Icons/icon";

const AccidentAssistance = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Auxílio Acidente"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          { page: "/auxilio-acidente", name: "Auxílio Acidente" },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            Benefício é pago como uma forma de indenização em razão de acidente
            e, portanto, não impede o cidadão de continuar exercendo o labor.
          </p>
          <p>
            O Auxílio-Acidente é um benefício previdenciário indenizatório pago
            pelo INSS.
          </p>
          <p>
            Ele será devido aos segurados que sofrerem qualquer categoria de
            acidente que resulte em sequelas ou, então, que diminua a capacidade
            laborativa do trabalhador.
          </p>
          <p>
            As sequelas devem ser permanentes e, também, deverá haver prejuízo
            na vida profissional do trabalhador.
          </p>
          <p>
            Apenas algumas categorias de trabalhadores possuem direito a este
            benefício, são elas:
          </p>

          <P.Checklist>
            <li>
              <Icon name="Check" /> <span>Empregados urbanos ou rurais;</span>
            </li>
            <li>
              <Icon name="Check" /> <span>Segurados especiais;</span>
            </li>
            <li>
              <Icon name="Check" /> <span>Empregados domésticos;</span>
            </li>
            <li>
              <Icon name="Check" /> <span>Trabalhadores avulsos.</span>
            </li>
          </P.Checklist>

          <p>
            Como deve ter ficado claro, os contribuintes facultativos e
            individuais, não possuem direito a receber o benefício.
          </p>
          <p>
            Um requisito essencial para que receba o benefício é estar
            contribuindo para a previdência, todavia não existe uma carência
            para receber, desta forma, não é necessário cumprir um número mínimo
            de contribuições, basta apenas que tenha começado a contribuir e já
            será possível receber o valor indenizatório.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Auxílio Acidente"
    description="Atendimento jurídico especializado em Auxílio Acidente. Garantimos orientação completa para assegurar seus direitos a benefícios por acidentes, com suporte confiável e eficiente."
  />
);

export default AccidentAssistance;
